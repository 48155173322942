import React, {
  useState,
  useEffect,
} from 'react'
import PropTypes from 'prop-types'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Layout from '../../components/Layout'
import CreationsRollDefault from '../../components/CreationsRoll'
import CustomPageContext from '../../Context/CustomPageContext'
import MetaTags from '../../components/MetaTags'
import appConfig from '../../config/config'

const CreationsIndexPage = ({ location }) => {
  const initUiTheme = typeof window !== 'undefined' ? localStorage.getItem('uiTheme') : null
  const [uiTheme, setUiTheme] = useState(initUiTheme || appConfig.defaultInitTheme)

  const theme = React.useMemo(
    () => createMuiTheme({
      palette: {
        // type: prefersDarkMode ? 'dark' : 'light',
        type: uiTheme,
        primary: { main: '#212121' },
        secondary: { main: '#DD2C00' }
      },
    }),
    [uiTheme],
  )

  useEffect(() => {
    // Sets the theme config to localstore
    if (uiTheme) {
      localStorage.setItem('uiTheme', uiTheme)
    }
  }, [uiTheme])

  return (
    <CustomPageContext.Provider
      value={{
        uiTheme,
        setUiTheme,
      }}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Layout location={location}>
          <MetaTags 
            pageTitle="Creations"
          />
          <CreationsRollDefault />
        </Layout>
      </ThemeProvider>
    </CustomPageContext.Provider>
  )
}

CreationsIndexPage.propTypes = {
  location: PropTypes.object,
}

export default CreationsIndexPage
